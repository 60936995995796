<template>
  <div>
    <div v-if="!coinPayments" style="text-align: center">
      <a-spin size="large" />
    </div>
    <div v-else>
      <div class="payment-info__wrapper" style="justify-content: center">
        <div class="qr-wrapper">
          <img
            :src="coinPayments.attributes.qr_url ?? coinPayments.attributes.exchange_info.qr"
            style="widhth: 300px"
            class="qr-code"
          />
        </div>
      </div>
      <div class="payment-info__wrapper">
        <a-statistic
          class="payment-info__amount"
          :value="coinPayments.attributes.exchange_amount ?? coinPayments.attributes.exchange_info.exchange_amount"
          :precision="8"
        >
          <template #title>
            <p class="bill__title">
              {{ i18n.t('start.statisticFrom') }}
              {{ coinPayments.attributes.exchange_currency ?? coinPayments.attributes.exchange_info.exchange_currency }}
            </p>
          </template>
        </a-statistic>
        <sdFeatherIcons class="bill__icon" type="arrow-right" size="18" />
        <a-statistic
          class="payment-info__amount"
          :value="coinPayments.attributes.amount ?? coinPayments.attributes.request_amount"
          :precision="2"
        >
          <template #title>
            <p class="bill__title">{{ i18n.t('start.statisticTo') }} USDT</p>
          </template>
        </a-statistic>
      </div>
      <sdAlerts
        :outlined="false"
        :closable="false"
        :showIcon="true"
        :message="
          i18n.t(
            'status.' +
              (coinPayments.attributes.status
                ? coinPayments.attributes.status.split('.').join('')
                : coinPayments.attributes.operation_status_text.split('.').join('')),
          )
        "
        :type="
          coinPayments.attributes.status == 'waiting' ||
          coinPayments.attributes.operation_status_text == 'waiting' ||
          coinPayments.attributes.status == 'Waiting for confirms...' ||
          coinPayments.attributes.operation_status_text == 'Waiting for confirms...' ||
          coinPayments.attributes.status == 'Waiting for confirms' ||
          coinPayments.attributes.operation_status_text == 'Waiting for confirms' ||
          coinPayments.attributes.operation_status_text == 'Waiting for buyer funds' ||
          coinPayments.attributes.status == 'Waiting for buyer funds' ||
          coinPayments.attributes.status == 'Waiting for buyer funds...' ||
          coinPayments.attributes.operation_status_text == 'Waiting for buyer funds...'
            ? 'warning'
            : (coinPayments.attributes.status == 'Complete' ??
                coinPayments.attributes.operation_status_text == 'Complete') ||
              coinPayments.attributes.operation_status_text == 'Funds received and confirmed, sending to you shortly' ||
              coinPayments.attributes.operation_status_text == 'Funds received and confirmed, sending to you shortly...'
            ? 'successfull'
            : 'error'
        "
        style="width: 100%"
      />
      <sdAlerts
        :outlined="false"
        :closable="false"
        :showIcon="true"
        :message="i18n.t('status.coin_rules')"
        type="info"
        style="width: 100%"
        v-if="coinPayments.attributes.operation_status == 0"
      />
      <a-statistic-countdown
        class="payment-info__transaction-time"
        :title="i18n.t('start.transactionTime')"
        :value="
          coinPayments.attributes.exchange_info
            ? new Date(coinPayments.attributes.created_at).getTime() +
              coinPayments.attributes.exchange_info.timeout * 1000
            : new Date(coinPayments.attributes.created_at).getTime() + coinPayments.attributes.timeout * 1000
        "
        @finish="closeSendModal"
        style="width: 100%"
      />

      <ul class="payment-info__transaction">
        <li>
          <span class="payment-info__transaction-title">{{ i18n.t('start.creationDate') }}</span>
          <span class="payment-info__transaction-text">{{ i18n.d(coinPayments.attributes.created_at, 'long') }}</span>
        </li>
        <li>
          <span class="payment-info__transaction-title">{{ i18n.t('start.transactionID') }}</span>
          <span class="payment-info__transaction-text">{{
            coinPayments.attributes.operation_id ?? coinPayments.attributes.exchange_info.txn_id
          }}</span>
        </li>
        <li>
          <span class="payment-info__transaction-title">{{ i18n.t('start.recieved') }}</span>
          <span class="payment-info__transaction-text">{{
            coinPayments.attributes.amount_payed ? coinPayments.attributes.amount_payed : 0.0
          }}</span>
        </li>
        <li>
          <span class="payment-info__transaction-title">{{ i18n.t('start.transactionAddress') }}</span>
          <span class="payment-info__transaction-text"
            >{{ coinPayments.attributes.operation_wallet ?? coinPayments.attributes.exchange_info.address }}
            <sdFeatherIcons
              @click="
                copyAddress(coinPayments.attributes.operation_wallet ?? coinPayments.attributes.exchange_info.address)
              "
              type="copy"
              size="14"
          /></span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { message } from 'ant-design-vue';
import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import QrcodeVue from 'qrcode.vue';
const DepositCoinpayments = {
  name: 'DepositCoinpayments',
  components: { QrcodeVue },
  props: {
    dataSource: Object,
    depositId: Number,
  },
  setup(props) {
    const route = useRoute();
    const { state, dispatch } = useStore();
    const coinPayments = computed(() => state.accounts.coinPaymentInfo);
    const i18n = useI18n();
    const copyAddress = (address) => {
      window.navigator.clipboard.writeText(address);
      message.success(i18n.t('messages.addressCopied'));
    };
    onMounted(() => {
      if (!route.params.actionType) {
        dispatch('getCoinpaymentsDepositInfo', props.depositId);
      } else {
        dispatch('getDashboard');
      }
    });
    return {
      i18n,
      copyAddress,
      coinPayments,
    };
  },
};
export default DepositCoinpayments;
</script>
<style scoped lang="scss">
.payment-info {
  .qr-code {
    text-align: center;
    margin: 0 auto 20px;
  }
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 30px;
  }
  &__amount {
    max-width: 50%;
  }
  &__transaction {
    background-color: rgb(244, 245, 247);
    border-radius: 5px;
    padding: 20px;

    &-time {
      background-color: rgb(244, 245, 247);
      margin: 20px 0;
      border-radius: 5px;
      padding: 10px;
    }
    li {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      font-size: 16px;
      color: #5a5f7d;
      font-weight: 500;
    }
    &-title {
      color: #5a5f7d;
      margin-right: 10px;
    }
    &-text i {
      cursor: pointer;
    }
  }
}
.transaction {
  &__title {
    font-weight: bold;
    font-size: 30px;
    line-height: 40px;
    color: #272443;
    margin-bottom: 20px;
  }
  &__info {
    font-weight: 450;
    font-size: 12px;
    line-height: 24px;
    color: #908f9e;
  }
  &__dialog {
    margin: 20px;
    padding: 0;
    text-align: left;
  }
}
.ant-form-item {
  margin: 0;
  display: block;
  .ant-select,
  input,
  button {
    width: 100%;
    margin: 0;
  }
}
li {
  overflow-wrap: break-word;
  overflow: hidden;
  @media screen and (max-width: 450px) {
    .payment-info__transaction-text {
      max-width: 130px;
    }
  }
  .payment-info__transaction-text {
    overflow-wrap: break-word;
    word-wrap: break-word;
    white-space: pre-wrap;
  }
}
.qr-wrapper {
  background: center / 20% url('~@/static/img/load.gif') no-repeat;
  height: 246px;
  width: 246px;
}
</style>
